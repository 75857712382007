import React from 'react'
import { graphql } from 'gatsby'

import Shop from './_shop'

export default props => (
  <Shop
    {...props}
    title="La boutique"
    subtitle="Ma boutique sur Etsy 🛍 💎 😍"
  />
)

export const pageQuery = graphql`
  query {
    site {
      ...SiteInformation
    }
    prismic {
      allShops(lang: "fr-fr") {
        edges {
          node {
            meta_title
            meta_keywords
            meta_description
            page_subtitle
            content_body
            _meta {
              id
            }
          }
        }
      }
    }
    allFeaturedEtsyListing {
      edges {
        node {
          id
          title
          tags
          url
          childrenEtsyListingImage {
            rank
            childFile {
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 300) {
                  ...GatsbyImageSharpFluid
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
        }
      }
    }
  }
`
